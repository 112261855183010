const search_forms = document.querySelectorAll('[data-search-form-name]');
for (let i = 0; i < search_forms.length; i++) {
  const search_form = search_forms[i];
  const search_form_name = search_form.getAttribute('data-search-form-name');

  const simple_help_table = document.querySelector(`#simple_help_content_${search_form_name}`);
  const advanced_help_table = document.querySelector(`#advanced_help_content_${search_form_name}`);
  const wildcard_table = document.querySelector(`#wildcard_table_${search_form_name}`);

  $(`#search_select_${search_form_name}`).find('.active-search').popover({
    trigger: 'hover',
    placement: 'bottom',
    html: true,
    title: '<strong>Search Results</strong>',
    content: 'This search request is active.',
  });

  $(`#simple_help_${search_form_name}`).popover({
    placement: 'left',
    html: true,
    title: '<strong>Basic Search Help</strong>',
    content: simple_help_table.innerHTML + wildcard_table.innerHTML
  });

  $(`#advanced_help_${search_form_name}`).popover({
    placement: 'left',
    html: true,
    title: '<strong>Advanced Search Help</strong>',
    content: advanced_help_table.innerHTML + wildcard_table.innerHTML
  });

  $(`.list-search-toggle[data-target="${search_form_name}"]`).on('click', (e) => {
    e.preventDefault();
    e.stopPropagation();

    const target = $(e.currentTarget);

    $(`.search-wrapper[data-search-form-name="${target.attr('data-target')}"]`).toggle();
    $(`#search_string_${search_form_name}`).focus();
  });

  $(`.list-search-jumper[data-target="${search_form_name}"]`).on('click', (e) => {
    e.preventDefault();
    e.stopPropagation();

    const target = $(e.currentTarget);

    $('html, body').animate({
      scrollTop: 0
    }, 0);

    $(`.search-wrapper[data-search-form-name="${target.attr('data-target')}"]`).show();
  });

  $(`.list-search-close[data-target="${search_form_name}"]`).on('click', (e) => {
    e.preventDefault();
    e.stopPropagation();

    const target = $(e.currentTarget);

    $(`.search-wrapper[data-search-form-name="${target.attr('data-target')}"]`).hide();
  });

  $(`#simple_button_${search_form_name}`).on('click', (e) => {
    e.preventDefault();
    e.stopPropagation();

    if ($(`#simple_search_${search_form_name}`).hasClass('active-search')) return;

    $(`#search_advanced_form_${search_form_name}`).val(false);
    $(`#simple_help_${search_form_name}`).popover('hide');
    $(`#advanced_help_${search_form_name}`).popover('hide');
    $(`#simple_button_${search_form_name}`).addClass('active-search');
    $(`#simple_search_${search_form_name}`).removeClass('hide');
    $(`#advanced_button_${search_form_name}`).removeClass('active-search');
    $(`#advanced_search_${search_form_name}`).addClass('hide');
  });

  $(`#advanced_button_${search_form_name}`).on('click', (e) => {
    e.preventDefault();
    e.stopPropagation();

    if ($(`#advanced_search_${search_form_name}`).hasClass('active-search')) return;

    $(`#search_advanced_form_${search_form_name}`).val(true);
    $(`#simple_help_${search_form_name}`).popover('hide');
    $(`#advanced_help_${search_form_name}`).popover('hide');
    $(`#simple_button_${search_form_name}`).removeClass('active-search');
    $(`#simple_search_${search_form_name}`).addClass('hide');
    $(`#advanced_button_${search_form_name}`).addClass('active-search');
    $(`#advanced_search_${search_form_name}`).removeClass('hide');
  });
}
