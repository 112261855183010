import consumer from "./consumer";
import {build_tr, display_events_data} from './utils';

(() => {
  const channel_name = "UserActivityChannel";

  const table_selector = "#websocket_table";
  const table_el = document.querySelector(table_selector);
  if (!table_el) return;

  const channel = consumer.subscriptions.create({channel: channel_name, room: "admin"}, {
    connected() {
      console.log("connected");
      channel.send({text: "hello"});
    },

    disconnected() {
      console.log("disconnected");
    },

    received(data) {
      console.log(data);
      parse_data(data);
    },
  });

  function parse_data(data) {
    const method = data["method"];
    const fullpath = data["fullpath"];
    const text = `${method} -> ${fullpath}`;
    build_tr(data, table_el, text);
  }

  // TODO: add filter based on user-id
  display_events_data('#user_activity_events_data', parse_data);
})();
