// calls to refresh template will return .template-well, so replaceWith the current template-well
// moving target.closest(template_container_selector) to a variable stops success action from completing
const template_container_selector = '.template-container';
const template_well_selector = '.template-well';

$('body').on('click', 'a[data-action="select-program-template"]', (e) => {
  fetchProgramTemplate(e);
});

$('body').on('change', 'select[data-action="select-program-template"]', (e) => {
  fetchProgramTemplate(e);
});

function fetchProgramTemplate(e) {
  const $target = $(e.currentTarget);

  const $template_container_el = $target.closest(template_container_selector);
  const $posting_el = $template_container_el.find('.posting');

  const script_params = $template_container_el.find('[name]').serialize();
  const selected_template = $template_container_el.find('[name="template"]').serialize();
  const selected_locale = $template_container_el.find('[name="locale"]').serialize();

  $.ajax({
    url: $target.attr('data-url'),
    method: 'post',
    data: `${script_params}&${selected_template}&${selected_locale}`,

    beforeSend: (xhr, settings) => {
      $posting_el.removeClass('hidden');
    },

    success: (data, textStatus, jqXHR) => {
      $posting_el.addClass('hidden');

      if (data['templates']) {
        const $templates_select_el = $('[name="template"]');
        const templates = data['templates'].map((value) => { return `<option>${value}</option>`; });
        const current = $templates_select_el.val();
        $templates_select_el.empty().append(templates).val(current);
      }

      if (data['template_well']) {
        const $template_well_el = $template_container_el.find(template_well_selector);
        $template_well_el.replaceWith(data['template_well']);
      }

      if (data['configs']) {
        $('#script_config_pane').html(data['configs']);
      }

      if (data['partials']) {
        $('#script_partials_pane').html(data['partials']);
      }
    }
  }).always(() => {
    $posting_el.addClass('hidden');
  });
}
