import {displayFlashMessenger} from "./messenger";
import {add_event_listener_ajax_success} from "./ujs_events";

add_event_listener_ajax_success('[data-remote=true]', (e, target, response, status, xhr) => {
  const data = response;

  const message = data['flash_msg'];
  if (message) {
    displayFlashMessenger(message, 'success');
  }

  const save_msg_element = data['save_msg_element'];
  if (save_msg_element) {
    const target = $(`#${save_msg_element}`);

    const target_class = 'green';
    target.addClass(target_class);
    setTimeout(function() {
      target.removeClass(target_class);
    }, 600);

    let icon = data['icon'];
    if (icon === 'circle') {
      icon = `icon-circle icon-circle-blank`;
    }
    else if (icon === 'check') {
      icon = `icon-check icon-check-empty`;
    }
    else if (icon === 'star') {
      icon = `icon-star icon-star-empty`;
    }
    else if (icon === 'glyphicon-fire') {
      icon = `glyphicon-fire glyphicon-fire active`;
    }
    else {
      icon = `icon-${icon} icon-${icon} active`;
    }
    target.children('i').toggleClass(icon);
  }

  const destination = data['dest'];
  if (destination) {
    window.location.replace(destination);
  }
});
