export const add_event_listener_ajax_before = (selector, callback = null) => {
  document.addEventListener('ajax:before', (e) => {
    const target = e.target.closest(selector);
    if (!target) return;
    if (typeof callback === typeof Function) {
      callback(e, target);
    }
  });
};

export const add_event_listener_ajax_before_send = (selector, callback = null) => {
  document.addEventListener('ajax:beforeSend', (e) => {
    const target = e.target.closest(selector);
    if (!target) return;
    if (typeof callback === typeof Function) {
      const [xhr, options] = e.detail;
      callback(e, target, xhr, options);
    }
  });
};

export const add_event_listener_ajax_send = (selector, callback = null) => {
  document.addEventListener('ajax:send', (e) => {
    const target = e.target.closest(selector);
    if (!target) return;
    if (typeof callback === typeof Function) {
      const [xhr] = e.detail;
      callback(e, target, xhr);
    }
  });
};

export const add_event_listener_ajax_stopped = (selector, callback = null) => {
  document.addEventListener('ajax:stopped', (e) => {
    const target = e.target.closest(selector);
    if (!target) return;
    if (typeof callback === typeof Function) {
      callback(e, target);
    }
  });
};

export const add_event_listener_ajax_success = (selector, callback = null) => {
  document.addEventListener('ajax:success', (e) => {
    const target = e.target.closest(selector);
    if (!target) return;
    if (typeof callback === typeof Function) {
      const [response, status, xhr] = e.detail;
      callback(e, target, response, status, xhr);
    }
  });
};

export const add_event_listener_ajax_error = (selector, callback = null) => {
  document.addEventListener('ajax:error', (e) => {
    const target = e.target.closest(selector);
    if (!target) return;
    if (typeof callback === typeof Function) {
      const [response, status, xhr] = e.detail;
      callback(e, target, response, status, xhr);
    }
  });
};

export const add_event_listener_ajax_complete = (selector, callback = null) => {
  document.addEventListener('ajax:complete', (e) => {
    const target = e.target.closest(selector);
    if (!target) return;
    if (typeof callback === typeof Function) {
      const [xhr, status] = e.detail;
      callback(e, target, xhr, status);
    }
  });
};

window.add_event_listener_ajax_before = add_event_listener_ajax_before;
window.add_event_listener_ajax_before_send = add_event_listener_ajax_before_send;
window.add_event_listener_ajax_send = add_event_listener_ajax_send;
window.add_event_listener_ajax_stopped = add_event_listener_ajax_stopped;
window.add_event_listener_ajax_success = add_event_listener_ajax_success;
window.add_event_listener_ajax_error = add_event_listener_ajax_error;
window.add_event_listener_ajax_complete = add_event_listener_ajax_complete;

/*
https://guides.rubyonrails.org/v6.0/working_with_javascript_in_rails.html#rails-ujs-event-handlers
ajax:before 		Before the whole ajax business.
ajax:beforeSend 	[xhr, options] 	Before the request is sent.
ajax:send 	[xhr] 	When the request is sent.
ajax:stopped 		When the request is stopped.
ajax:success 	[response, status, xhr] 	After completion, if the response was a success.
ajax:error 	[response, status, xhr] 	After completion, if the response was an error.
ajax:complete 	[xhr, status] 	After the request has been completed, no matter the outcome.
*/
