import consumer from "./consumer";
import {build_tr, display_events_data} from './utils';

(() => {
  const channel_name = "UserChatChannel";

  const table_selector = "#chat_table";
  const table_el = document.querySelector(table_selector);
  if (!table_el) return;

  const channel = consumer.subscriptions.create({channel: channel_name, room: "chat"}, {
    connected() {
      console.log("connected");
      channel.send({message_type: "initial", text: "hello"});
    },

    disconnected() {
      console.log("disconnected");
    },

    received(data) {
      console.log(data);
      parse_data(data);
    },
  });

  function parse_data(data) {
    if (data["message_type"] === "chat_message") {
      const text = data["text"];
      const tr = build_tr(data, table_el, text);
      console.log(tr);
      if (data["upload_filename"]) {
        tr.querySelector('td:nth-child(2)').innerHTML += `<br>
                                                          <a href="${data["upload_url"]}" target="_blank">${data["upload_filename"]}</a>`;
      }
    }
  }

  display_events_data('#chat_events_data', parse_data);

  document.addEventListener('submit', (e) => {
    const form_el = e.target.closest('form');
    if (!form_el) return;
    e.preventDefault();
    e.stopPropagation();

    const text_el = form_el.querySelector(`[type="text"]`);
    const file_el = form_el.querySelector(`[type="file"]`);

    fetch(form_el.action, {
      method: form_el.method,
      body: new FormData(form_el),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        // 'Accept': 'application/json',
        // 'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        text_el.value = "";
        file_el.value = "";
        console.log(data);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
      });

    // channel.send({message_type: 'chat_message', text: text_el.value});
    // text_el.value = "";
  });
})();
