// Lead note time stamp function
// when inserting a timestamp, add the timestamp to the text box, the move the cursor to the end of the inserted timestamp
function do_timestamp(target, action, focus = true) {
  if (!target) return;

  let timestamp_data = {};
  const timestamp_data_el = document.getElementById("timestamp_data");
  if (timestamp_data_el) {
    timestamp_data = JSON.parse(timestamp_data_el.innerHTML);
  }
  const user_name = timestamp_data["user_name"] ? `${timestamp_data["user_name"]} - ` : '';
  const prefix = timestamp_data["prefix"] ? `${timestamp_data["prefix"]}` : '';
  const suffix = timestamp_data["suffix"] ? `${timestamp_data["suffix"]}` : '';

  const date = new Date();
  const locale_date_string = date.toLocaleDateString("en-US");
  const locale_time_string = date.toLocaleTimeString("en-US");
  const offset_string = `GMT${-(date.getTimezoneOffset() / 60)}`;
  const current_value = target.value;

  let value = '';
  let timestamp = '';

  if (action === 'prepend') {
    const date_string = `${locale_date_string} ${locale_time_string} ${offset_string} - `;
    timestamp = `${user_name}${date_string}`;
    value = `${prefix}${timestamp}${suffix}\n\n${current_value}`;
  }
  else if (action === 'append') {
    const date_string = `${current_value.length > 0 ? "\n\n" : ''}${locale_date_string} ${locale_time_string} ${offset_string} - `;
    timestamp = `${user_name}${current_value}${date_string}`;
    value = `${prefix}${timestamp}${suffix}`;
  }

  target.value = value;
  target.setSelectionRange(timestamp.length, timestamp.length);
  if (focus) target.focus();
}

function prependDateTime(input_id, focus = true) {
  do_timestamp(input_id, 'prepend', focus);
}

function appendDateTime(input_id, focus = true) {
  do_timestamp(input_id, 'append', focus);
}

//we have to check if we added the timestamp with the link or else the timestamp is added twice
let clicked = false;
$('body').on('click', 'a[data-action="prepend-date-time"]', (e) => {
  const selector = e.currentTarget.getAttribute('data-target');
  const target = document.querySelector(selector);
  clicked = true;
  prependDateTime(target);
  clicked = false;
});

$('body').on('click', 'a[data-action="append-date-time"]', (e) => {
  const selector = e.currentTarget.getAttribute('data-target');
  const target = document.querySelector(selector);
  clicked = true;
  appendDateTime(target);
  clicked = false;
});

// when textarea is focused, save the original contents of the textbox, prepend timestamp, and save new contents
let o = null;
let o2 = null;
$('body').on('focus', 'textarea[data-action="prepend-date-time"]', (e) => {
  //if we clicked the add timestamp link, return so the timestamp isn't added twice
  if (clicked) return;
  const target = e.currentTarget;
  o = target.value;
  prependDateTime(target);
  o2 = target.value;
});

// when textarea is blurred, restore the original contents of the textbox, and set the saved values to null
$('body').on('blur', 'textarea[data-action="prepend-date-time"]', (e) => {
  const target = e.currentTarget;
  if (target.value === o2) {
    target.value = o;
  }
  o = null;
  o2 = null;
});

window.prependDateTime = prependDateTime;
window.appendDateTime = appendDateTime;
