import "../utilities/add_date_time";
import "../utilities/prevent_unload";
import "../utilities/events";
import "../utilities/removable-row";
import "../utilities/collapsible";
import "../mcrm/template_select";
import "../mcrm/utilities";
import "../mcrm/my_codemirror";
import "../mcrm/ajax_form";
import "../mcrm/sales_timeline";
import "../mcrm/settings";
import "../mcrm/search/basic_search";
import "../mcrm/telescript_show";
import "../channels/index"

// https://github.com/rails/rails/issues/49499
// https://stackoverflow.com/a/57543894
import Rails from "rails-ujs";

Rails.start();
