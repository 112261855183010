export const add_event_listener = (type, selector, callback = null) => {
  document.addEventListener(type, (e) => {
    const target = e.target.closest(selector);
    if (!target) return;
    if (typeof callback === typeof Function) {
      callback(e, target);
    }
  });
};

export const add_event_listener_click = (selector, callback = null) => {
  add_event_listener('click', selector, callback);
};

export const add_event_listener_change = (selector, callback = null) => {
  add_event_listener('change', selector, callback);
};
