// so that when clicking back on filters_users_path links send you to the right place
// data-remote="true" links hrefs must contain ?dest= or &dest= for this to work
$('body').on('click', '[data-remote="true"][href*="?dest="], [data-remote="true"][href*="&dest="]', function() {
  history.pushState({}, document.title, document.location);
});

$('.action-tip').tooltip();

$('body').find('input[type="button"][value="Clear"]').on('click', (e) => {
  $(e.currentTarget).closest('form').find('input[type="date"]').val('');
});

$('body').on('click', '[data-action="select-all"]', (e) => {
  e.preventDefault();
  e.stopPropagation();

  const target = $(e.currentTarget);

  const event_name = target.attr('data-event-name');
  const parent = target.closest(target.attr('data-toggle-parent'));
  const state = target.attr('data-toggle') === 'true';
  const checks = parent.find(target.attr('data-target'));

  $('body').trigger('selected-all', {
    currentTarget: e.currentTarget,
    event_name: event_name,
    parent: parent,
    state: state,
    checks: checks,
  });

  checks.prop('checked', state);
  target.attr('data-toggle', !state);
});

export const getPlaceholderContents = ($placeholder, path, data = {}, callback = null) => {
  const placeholder = $placeholder[0];
  if (!placeholder) return;

  placeholder.innerHTML = '<i class="placeholder-spinner icon-spinner icon-spin icon-large"></i>';

  let full_url = path;
  const params = new URLSearchParams(data);
  if (params.size > 0) {
    // if params already passed, include these with &
    if (path.match(/\?/)) {
      full_url = path + '&' + params;
    }
    else {
      full_url = path + '?' + params;
    }
  }

  fetch(full_url, {
    method: "GET",
    headers: {
      // "Accept": "application/json", //FIXME: partials have to renamed from .html.erb to .erb to fix ActionView::MissingTemplate - Missing partial
      // "HTTP_X_REQUESTED_WITH": "XMLHttpRequest", //so that request.xhr? will be true, this version doesn't work
      'X-Requested-With': 'XMLHttpRequest', //so that request.xhr? will be true
    },
    // body: JSON.stringify(data), //TypeError: Window.fetch: HEAD or GET Request cannot have a body.
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      placeholder.innerHTML = data['html'];
      if (typeof callback === typeof Function) {
        callback(data);
      }
    })
    .catch(error => {
      console.error(error);
      placeholder.innerHTML = error;
    })
    .finally(() => {
      const spinner = placeholder.querySelector('.placeholder-spinner');
      if (spinner) spinner.remove();
    });
};

window.getPlaceholderContents = getPlaceholderContents;
