const lead_flags_header = $('.lead-flags');

hide_lead_flags(0);

$(document).on('click', '.show-more', (e) => {
  const $target = $(e.currentTarget);
  if (lead_flags_header.hasClass('closed')) {
    $target.text($target.attr('data-opened-text'));
    show_lead_flags();
  }
  else {
    $target.text($target.attr('data-closed-text'));
    hide_lead_flags();
  }
});

function show_lead_flags(speed = 250) {
  if (!document.querySelector('.lead-flags')) return;

  const visible_flags = $('.lead-flags').attr('data-visible-flags');
  $('.lead_flag').slice(visible_flags ? visible_flags : 5).slideDown(speed);

  lead_flags_header.addClass('open');
  lead_flags_header.removeClass('closed');
}

function hide_lead_flags(speed = 250) {
  if (!document.querySelector('.lead-flags')) return;

  const visible_flags = $('.lead-flags').attr('data-visible-flags');
  $('.lead_flag').slice(visible_flags ? visible_flags : 5).slideUp(speed);

  lead_flags_header.removeClass('open');
  lead_flags_header.addClass('closed');
}
