//always include the CSRF token
import {add_event_listener_ajax_before_send, add_event_listener_ajax_complete, add_event_listener_ajax_error, add_event_listener_ajax_success} from "./ujs_events";

$.ajaxSetup({
  headers: {
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
  },
  //crossDomain: false,
});

//disable submit button on submit and reenable after submit is complete
$('body').on('submit', 'form', () => {
  // const target = $(e.currentTarget);
  // target.find('[type="submit"]').prop('disabled', true);
});

add_event_listener_ajax_before_send('form', (e, target, xhr, options) => {
  const $target = $(target);
  $target.find('[type="submit"]').prop('disabled', true);
  $target.find('.sending').removeClass('hide');
  $('body').find('.ajax-response').text('');
});

add_event_listener_ajax_success('form', (e, target, response, status, xhr) => {
  const data = response;

  const $target = $(target);
  $target.find('.sending').addClass('hide');

  $('body').find('.ajax-response').text(data['message']);
  $target.closest('.modal').modal('hide');
});

add_event_listener_ajax_error('form', (e, target, response, status, xhr) => {
  const $target = $(target);
  $target.find('.sending').addClass('hide');

  let text = '';

  if (xhr.responseJSON) {
    text = xhr.responseJSON.message;
  }
  else if (xhr.responseText) {
    text = xhr.responseText;
  }
  else {
    text = 'There was an error. Check the console.';
  }

  $('body').find('.ajax-response').text(text);
  console.error(xhr);
});

add_event_listener_ajax_complete('form', (e, target, xhr, status) => {
  const $target = $(target);
  $target.find('[type="submit"]').prop('disabled', false);
});

$('body').on('click', 'a[data-action]', (e) => {
  if (!is_action_href(e.currentTarget)) return;
  e.preventDefault();
  e.stopPropagation();
});

function is_action_href(target) {
  const href = target.getAttribute('href');
  return href === null || href === '' || href === '#';
}

