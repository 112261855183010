import {add_event_listener_click} from "./events";

(() => {
  add_event_listener_click('.collapse-button, .collapsible-header', (e, target) => {
    const collapse_button = target;
    const has_collapsible = collapse_button.closest(".has-collapsible");
    if (!has_collapsible) return;

    e.preventDefault();
    e.stopPropagation();

    const collapsibles = has_collapsible.querySelectorAll(".collapsible");
    for (const collapsible of collapsibles) {
      if (has_collapsible.closest(".closed")) {
        has_collapsible.classList.remove("closed");
        has_collapsible.classList.add("open");
      }
      else {
        has_collapsible.classList.add("closed");
        has_collapsible.classList.remove("open");
      }
    }
  });
})();
