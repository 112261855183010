import 'messenger-hubspot/build/js/messenger';

export const displayFlashMessenger = (message, type = 'success') => {
  Messenger({
    extraClasses: 'messenger-fixed messenger-on-bottom msg',
    theme: 'flat',
  }).post({
    message: message,
    type: type,
    showCloseButton: true,
    hideAfter: 5,
  });
};
