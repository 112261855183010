import {add_event_listener_change} from "../utilities/events";

const spoke_to_decision_maker_selector = '[name="script[spoke_to_decision_maker]"]';
const spoke_to_decision_maker_checked_selector = `${spoke_to_decision_maker_selector}:checked`;
const spoke_to_decision_maker_name_selector = '[name="script[decision_maker_name]"]';
const label_selector = '[for="script_decision_maker_name"]';

handle_spoke_to_decision_maker();
add_event_listener_change(spoke_to_decision_maker_selector, (e, target) => {
  handle_spoke_to_decision_maker();
});

function handle_spoke_to_decision_maker() {
  const el = document.querySelector(spoke_to_decision_maker_selector);
  const checked_el = document.querySelector(spoke_to_decision_maker_checked_selector);
  const name_el = document.querySelector(spoke_to_decision_maker_name_selector);
  const label = document.querySelector(label_selector);

  if (!el) return;
  if (!checked_el) return;
  if (!name_el) return;
  if (!label) return;

  if (checked_el.value.toLowerCase() !== 'no') {
    name_el.required = true;
    label.style['color'] = 'red';
  }
  else {
    name_el.required = false;
    label.style['color'] = '';
  }
}
