export const build_tr = (data, table_el, text) => {
  const datetime = data['datetime'];
  const json_log = JSON.parse(data["json_log"]);
  const id = json_log['id'];
  const email = json_log['email'];
  const name = json_log['name'];

  const tbody = table_el.querySelector('tbody');
  const row = document.createElement('tr');

  const td1 = document.createElement('td');
  td1.innerText = name;
  row.append(td1);

  const td2 = document.createElement('td');
  td2.innerText = text;
  row.append(td2);

  const td3 = document.createElement('td');
  td3.innerText = datetime;
  row.append(td3);

  row.dataset["user_id"] = id;
  tbody.prepend(row);

  return row;
};

export const display_events_data = (selector, callback) => {
  const data_el = document.querySelector(selector);
  if (!data_el) return;
  const _data = JSON.parse(data_el.innerHTML);
  for (const data of _data) {
    callback(data);
  }
};
