// https://codemirror.net/examples/gutter/
import {keymap, EditorView, lineNumbers, gutter} from "@codemirror/view";
import {defaultKeymap, history, historyKeymap} from "@codemirror/commands";
import {autocompletion} from "@codemirror/autocomplete";
import {syntaxHighlighting, defaultHighlightStyle} from "@codemirror/language";
import {javascript} from "@codemirror/lang-javascript";
import {css} from "@codemirror/lang-css";
import {html} from "@codemirror/lang-html";

function create_editors() {
  const codemirrors = document.querySelectorAll('[data-codemirror="true"]');
  for (let i = 0; i < codemirrors.length; i++) {
    editorFromTextArea(codemirrors[i]);
  }
}

// https://codemirror.net/docs/migration/#codemirror.fromtextarea
function editorFromTextArea(textarea) {
  const view = new EditorView({
    doc: textarea.value,
    extensions: [
      keymap.of([...defaultKeymap, ...historyKeymap]),
      lineNumbers(),
      gutter({class: "cm-mygutter"}),
      syntaxHighlighting(defaultHighlightStyle),
      history(),
      autocompletion(),
      css(),
      javascript(),
      html(),
    ],
  });

  textarea.parentNode.insertBefore(view.dom, textarea);
  textarea.style.display = "none";
  if (textarea.form) {
    textarea.form.addEventListener("submit", () => {
      textarea.value = view.state.doc.toString();
    });
  }

  return view;
}

create_editors();
